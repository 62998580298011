<template>
    <nl-loader v-if="preloading" :show="true" />
    <div v-else class="box">
        <NlTable
            :columns.sync="columns"
            :get-data="loadAsyncData"
            :left-buttons="buttons"
            :force-update="forceUpdate"
            checkable
            :checked-rows.sync="checkedRows"
            :loading="isGenerating"
            :kpis="kpis"
        >
            <template #kpis="{ meta }">
                <b-taglist attached>
                    <b-tag type="is-warning" size="is-medium"> Spots</b-tag>
                    <b-tag type="is-dark" size="is-medium">
                        {{ meta.total }}
                    </b-tag>
                </b-taglist>
            </template>

            <template #domain="{ props: { row, column } }">
                <ColumnEditable
                    :type="'input'"
                    :init-value="row.domain"
                    :site-id="row.id"
                    :field="column.field"
                    @input-blur="updateSite(row, $event)"
                />
            </template>

            <template #url="{ props: { row, column } }">
                <ColumnEditable
                    :type="'input'"
                    :init-value="row.url"
                    :site-id="row.id"
                    :field="column.field"
                    @input-blur="updateSite(row, $event)"
                />
            </template>

            <template #is_active="{ props }">
                <b-switch
                    type="is-success"
                    :true-value="1"
                    :false-value="0"
                    v-model="props.row.is_active"
                    @input="updateSite(props.row, $event)"
                ></b-switch>
            </template>

            <template #group_idSearch="{ props }">
                <b-select
                    v-model="props.filters[props.column.field]"
                    expanded
                    size="is-small"
                    :placeholder="props.label"
                >
                    <option :value="null">Tous</option>
                    <option v-for="i in groups" :key="i.id" :value="i.id">
                        {{ i.name }}
                    </option>
                </b-select>
            </template>

            <template
                #group_id="{
                    props: {
                        row,
                        column: { field }
                    }
                }"
            >
                <ColumnEditable
                    :type="'select'"
                    :groups="groups"
                    :init-value="row.group_id"
                    :site-id="row.id"
                    :field="field"
                    @input-blur="updateSite(row, $event)"
                >
                    <option v-for="group in groups" :key="group.id" :value="group.id">
                        <div v-if="group.parent_id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ group.name }}</div>

                        <div v-else>
                            {{ group.name }}
                        </div>
                    </option>
                </ColumnEditable>
            </template>

            <template #wp_data="{ props: { row } }">
                <NlWordpress site-type="spot" :site-data="row" />
            </template>

            <template #count_total="{ props: { row } }">
                <span>
                    {{ row.count_total }}
                    <i class="fas fa-arrow-right mx-2" />
                    {{ row.count_completed }}
                </span>
            </template>

            <template #count_totalSearch="{ props }">
                <div class="d-flex">
                    <b-numberinput
                        v-model="props.filters['min_spot_content_count_total']"
                        :controls="false"
                        size="is-small"
                        placeholder="min."
                    />
                    <b-numberinput
                        v-model="props.filters['max_spot_content_count_total']"
                        :controls="false"
                        size="is-small"
                        placeholder="max."
                    />
                    <b-numberinput
                        v-model="props.filters['min_spot_content_count_completed']"
                        :controls="false"
                        size="is-small"
                        placeholder="min."
                        class="ml-1"
                    />
                    <b-numberinput
                        v-model="props.filters['max_spot_content_count_completed']"
                        :controls="false"
                        size="is-small"
                        placeholder="max."
                    />
                </div>
            </template>

            <template #content_items_status="{ props: { row } }">
                <span>
                    {{ row.count_ai_completed }}
                    <i class="fas fa-arrow-right mx-2" />
                    {{ row.count_published }}
                </span>
            </template>
            <template #content_items_statusSearch="{ props }">
                <b-select v-model="props.filters['content_items_status']" size="is-small" expanded>
                    <option :value="null">
                        {{ $t('global.all') }}
                    </option>
                    <option value="ai_in_progress">En cours</option>
                    <option value="ai_generated">Généré</option>
                    <option value="ai_canceled">Annulé</option>
                </b-select>
            </template>

            <template #actions="{ props: { row } }">
                <div class="d-flex justify-content-start m-auto">
                    <router-link :to="'/admin/spots/orders/' + row.id" class="ml-2">
                        <b-button icon-left="eye" icon-pack="fas" type="is-light" />
                    </router-link>
                    <router-link :to="`/admin/spots/${row.id}/content_items/?url=${row.url}`" class="ml-2">
                        <b-button icon-left="magic" icon-pack="fas" type="is-light" />
                    </router-link>
                    <b-button
                        v-if="row.count_total === 0"
                        class="ml-2"
                        icon-left="trash-alt"
                        icon-pack="fas"
                        type="is-danger"
                        @click="confirmDelete(row)"
                    />
                </div>
            </template>
        </NlTable>
        <AddSpotSiteModal ref="addSpotModal" :groups="groups" @addSites="createSites" />
        <AddContentItemModal :spot-ids="checkedRows" @addContentItem="saveContentItem" ref="addContentItemModal" />
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import AddSpotSiteModal from '@/components/Spots/AddSpotSiteModal.vue'
    import { mapActions } from 'vuex'
    import NlWordpress from '@/components/UI/Wordpress/NlWordpress.vue'
    import ColumnEditable from '@/components/Admin/Sites/ColumnEditable.vue'
    import AddContentItemModal from '../../components/Spots/AddContentItemModal.vue'

    export default {
        name: 'SpotsSites',
        components: { AddContentItemModal, ColumnEditable, NlWordpress, AddSpotSiteModal, NlTable },
        title: 'Sites Spots',
        data() {
            return {
                checkedRows: [],
                isGenerating: false,
                groups: null,
                preloading: false,
                forceUpdate: false,
                editableActive: {
                    url: false,
                    domain: false,
                    thematic: false
                },
                buttons: [
                    {
                        label: 'Ajouter un site',
                        icon: 'plus-circle',
                        type: 'is-warning',
                        action: this.addSite
                    },
                    {
                        label: 'Ajouter des articles',
                        icon: 'plus-circle',
                        type: 'is-warning',
                        action: this.addContentItems,
                        disabled: true
                    },
                    {
                        label: '',
                        icon: 'sync-alt',
                        type: 'is-dark',
                        action: () => (this.forceUpdate = !this.forceUpdate)
                    }
                ],
                columns: [
                    {
                        custom: true,
                        field: 'domain',
                        searchable: true,
                        sortable: true,
                        label: 'NDD'
                    },
                    {
                        custom: true,
                        field: 'url',
                        searchable: false,
                        sortable: true,
                        label: 'URL'
                    },
                    {
                        custom: true,
                        field: 'is_active',
                        searchable: false,
                        sortable: true,
                        label: 'Actif'
                    },
                    {
                        custom: true,
                        searchable: true,
                        customSearch: true,
                        sortable: true,
                        field: 'group_id',
                        label: 'Thématique'
                    },
                    {
                        custom: true,
                        centered: false,
                        searchable: false,
                        sortable: false,
                        field: 'wp_data',
                        label: 'WP'
                    },
                    {
                        centered: true,
                        searchable: false,
                        sortable: true,
                        field: 'rd_count',
                        label: 'RD'
                    },
                    {
                        label: 'Nombre de commandes',
                        field: 'count_total',
                        custom: true,
                        customSearch: true,
                        searchable: true,
                        sortable: true,
                        width: '200px'
                    },
                    {
                        label: 'Commandes IA',
                        field: 'content_items_status',
                        custom: true,
                        customSearch: true,
                        searchable: true,
                        sortable: true
                    },
                    {
                        sortable: false,
                        searchable: false,
                        custom: true,
                        field: 'actions',
                        label: 'Action',
                        width: '150px'
                    }
                ],
                kpis: [
                    {
                        label: 'Crédits TextBulker',
                        value: 0
                    },
                    {
                        label: 'Contenus en cours',
                        value: 0
                    },
                    {
                        label: 'Contenus générés',
                        value: 0
                    }
                ]
            }
        },
        watch: {
            checkedRows() {
                this.buttons[1].disabled = this.checkedRows.length <= 0
            }
        },
        created() {
            this.getGroups().then(response => {
                this.groups = response.groups
            })
        },
        methods: {
            ...mapActions('spots', {
                getSpots: 'list',
                createSpots: 'create',
                updateSpot: 'update',
                delete: 'delete',
                createBulkContentItem: 'createBulkContentItem',
                getRemainingCredits: 'getRemainingCredits',
                getContentItemsCount: 'getContentItemsCount'
            }),
            ...mapActions('group', {
                getGroups: 'adminList'
            }),
            loadAsyncData(params) {
                this.getRemainingCredits().then(res => {
                    this.kpis[0].value = this.$options.filters.toCurrency(res)
                })

                this.getContentItemsCount('ai_in_progress').then(res => {
                    this.kpis[1].value = res
                })

                this.getContentItemsCount('ai_completed').then(res => {
                    this.kpis[2].value = res
                })

                return this.getSpots(params)
            },
            addSite() {
                this.$refs.addSpotModal.show()
            },
            editSite(row) {
                this.$refs.editSpotModal.show(row)
            },
            createSites(group_id, urls) {
                let params = {
                    group_id: group_id,
                    urls: urls
                }
                this.createSpots(params)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(() => {
                        this.$store.dispatch('toastError', 'Une erreur est survenue')
                    })
            },
            updateSite(site, data) {
                if (data.field == 'domain') {
                    site.domain = data.value
                } else if (data.field == 'url') {
                    site.url = data.value
                } else if (data.field == 'group_id') {
                    site.group_id = data.value
                }

                this.updateSpot(site)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(() => {
                        this.$store.dispatch('toastError', 'Une erreur est survenue')
                    })
            },
            deleteSpot(spot) {
                this.delete(spot)
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            message: 'Le spot a été supprimé'
                        })
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
            },
            confirmDelete(row) {
                this.$buefy.dialog.confirm({
                    title: 'Supprimer le spot',
                    message: 'Êtes-vous sûr de vouloir supprimer le spot ' + row.domain + ' ?',
                    confirmText: 'Supprimer',
                    cancelText: 'Annuler',
                    type: 'is-danger',
                    size: 'is-medium',
                    onConfirm: () => {
                        this.deleteSpot(row.id)
                    }
                })
            },
            findGroupName(id) {
                let group = this.groups.find(group => group.id === id)
                return group ? group.name : ''
            },
            formatWPMeta(meta) {
                if (!meta) {
                    return null
                } else {
                    return JSON.parse(meta)
                }
            },
            computeWpStatus(data) {
                if (data.is_active && data.get_infos && data.get_categories && data.get_authors && data.post_articles) {
                    return 'is-success'
                } else if (
                    data.is_active &&
                    (!data.get_infos || !data.get_categories || !data.get_authors || !data.post_articles)
                ) {
                    return 'is-warning'
                } else {
                    return 'is-danger'
                }
            },
            addContentItems() {
                this.$refs.addContentItemModal.show()
            },
            saveContentItem(contentItem) {
                this.isGenerating = true
                this.createBulkContentItem({
                    params: {
                        spots: this.checkedRows.map(spot => spot.id),
                        tag_title: contentItem.tag_title,
                        meta_title: contentItem.meta_title
                    }
                })
                    .then(() => {
                        this.checkedRows = []
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'La génération des contenus a bien été lancée'
                        })
                        this.forceUpdate = !this.forceUpdate
                    })
                    .catch(() => {
                        this.$store.dispatch('toastError', 'Une erreur est survenue')
                    })
                    .finally(() => (this.isGenerating = false))
            }
        }
    }
</script>

<style scoped></style>